import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fieldTextValueChange } from "redux/simpleSearchSlice";
import PropTypes from "prop-types";
import SuggestedFieldPopupCompanies from "./SuggestedFieldPopupCompanies";
import SuggestedFieldPopupCompanyContacts from "./SuggestedFieldPopupCompanyContacts";
import SuggestedFieldPopupMatterFields from "./SuggestedFieldPopupMatterFields";
import SuggestedFieldPopupMatterRecords from "./SuggestedFieldPopupMatterRecords";
import SuggestedFieldPopupMatterReferences from "./SuggestedFieldPopupMatterReferences";
import SuggestedFieldPopupProjectRecords from "./SuggestedFieldPopupProjectRecords";
import { splitAndCleanStringToArray } from "utilities/stringAndArray";
import InputDebounced from "components/global/InputDebounced";

function SuggestedFieldPopupString({
  field,
  filter,
  operator,
  isFieldNameMatch,
  qualified,
  qualifiedDictionary,
  valueArray,
  onOperatorChange,
  onValueChange,
  onIsApplicableChange,
}) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const dispatch = useDispatch();

  const [fieldText, setFieldText] = useState(isFieldNameMatch ? "" : simpleSearch.fieldText);

  // Handle initial string feed from main search input
  useEffect(() => {
    onIsApplicableChange(fieldText.length > 0);
    const outputArray = splitAndCleanStringToArray(fieldText);
    onValueChange(outputArray);
  }, []);

  useEffect(() => {
    if (filter && filter !== undefined && filter.qualified !== true) {
      setFieldText(filter.valueArray?.join(", "));
    }
  }, [filter]);

  const checkIsApplicable = (operatorLocal, textArrayLocal) => {
    if (!textArrayLocal) textArrayLocal = valueArray;
    if (operatorLocal === "isempty" || operatorLocal === "isnotempty") {
      onIsApplicableChange(true);
    } else onIsApplicableChange(textArrayLocal.length > 0);
  };

  const handleOperatorChange = (operatorLocal) => {
    onOperatorChange(operatorLocal);
    if (qualified) {
      onIsApplicableChange(true);
    } else {
      checkIsApplicable(operatorLocal);
    }
  };

  const handleQualifiedListChange = (newValueArray, newQualified, newQualifiedDictionary) => {
    if (newQualified) {
      switch (operator) {
        case "=":
        case "<>":
        case "isempty":
        case "isnotempty":
          break;
        default:
          onOperatorChange("=");
      }
    }
    onValueChange(newValueArray, newQualified, newQualifiedDictionary);
  };

  const handleStringChange = (e) => {
    const text = e.target.value;
    if (!qualified) {
      const outputArray = splitAndCleanStringToArray(text);
      checkIsApplicable(operator, outputArray);
      onValueChange(outputArray);
    }
    setFieldText(text);
    dispatch(fieldTextValueChange(text));
  };

  const handleQualifiedStringChange = (text) => {
    dispatch(fieldTextValueChange(text));
  };

  let renderOperators = null;
  if (qualified) {
    renderOperators = (
      <div className="suggested-field-popup__operator">
        {/* <FaAngleDown /> */}
        <select value={operator} onChange={(e) => handleOperatorChange(e.target.value)} tabIndex={0}>
          <option value="=">Includes</option>
          <option value="<>">Excludes</option>
          <option value="isempty">Empty</option>
          <option value="isnotempty">Not Empty</option>
        </select>
      </div>
    );
  } else {
    renderOperators = (
      <div className="suggested-field-popup__operator">
        <select value={operator} onChange={(e) => handleOperatorChange(e.target.value)} tabIndex={0}>
          <option value="=">Equals</option>
          <option value="<>">Not Equals</option>
          <option value="contains">Contains</option>
          {!simpleSearch.queryType.isExternal && (
            <>
              <option value="notcontains">Not Contains</option>
              <option value="isempty">Empty</option>
              <option value="isnotempty">Not Empty</option>
            </>
          )}
          <option value="begins">Starts With</option>
          <option value="ends">Ends With</option>
        </select>
      </div>
    );
  }

  let renderQualifiedList = null;
  if (field.qualifyType === "APPLICATION_NUMBER" || field.qualifyType === "REGISTRATION_NUMBER")
    renderQualifiedList = (
      <SuggestedFieldPopupMatterFields
        field={field}
        valueArray={valueArray}
        qualified={qualified}
        qualifiedDictionary={qualifiedDictionary}
        onValueChange={handleQualifiedListChange}
        onIsApplicableChange={onIsApplicableChange}
      />
    );
  else if (field.qualifyType === "COMPANY" || field.qualifyType === "COMPANY_LINK")
    renderQualifiedList = (
      <SuggestedFieldPopupCompanies
        field={field}
        valueArray={valueArray}
        qualified={qualified}
        qualifiedDictionary={qualifiedDictionary}
        onValueChange={handleQualifiedListChange}
        onIsApplicableChange={onIsApplicableChange}
      />
    );
  else if (field.qualifyType === "COMPANYCONTACT")
    renderQualifiedList = (
      <SuggestedFieldPopupCompanyContacts
        field={field}
        valueArray={valueArray}
        qualified={qualified}
        qualifiedDictionary={qualifiedDictionary}
        onValueChange={handleQualifiedListChange}
        onIsApplicableChange={onIsApplicableChange}
      />
    );
  else if (field.qualifyType === "MATTER" || field.qualifyType === "MATTER_LINK")
    renderQualifiedList = (
      <SuggestedFieldPopupMatterRecords
        field={field}
        valueArray={valueArray}
        qualified={qualified}
        onValueChange={handleQualifiedListChange}
        onIsApplicableChange={onIsApplicableChange}
      />
    );
  else if (field.qualifyType === "PROJECT")
    renderQualifiedList = (
      <SuggestedFieldPopupProjectRecords
        field={field}
        valueArray={valueArray}
        qualified={qualified}
        qualifiedDictionary={qualifiedDictionary}
        onValueChange={handleQualifiedListChange}
        onIsApplicableChange={onIsApplicableChange}
      />
    );
  else if (field.qualifyType === "REFERENCE")
    renderQualifiedList = (
      <SuggestedFieldPopupMatterReferences
        field={field}
        valueArray={valueArray}
        qualified={qualified}
        qualifiedDictionary={qualifiedDictionary}
        onValueChange={handleQualifiedListChange}
        onIsApplicableChange={onIsApplicableChange}
      />
    );

  let renderInput = null;
  if (operator !== "isempty" && operator !== "isnotempty") {
    if (renderQualifiedList) {
      renderInput = <InputDebounced value={fieldText} onChange={handleQualifiedStringChange} />;
    } else {
      renderInput = <input value={fieldText} onChange={handleStringChange} autoComplete="off" tabIndex={0} />;
    }
  }

  return (
    <>
      <div className="flex-row-start">
        {renderOperators}
        {renderInput}
      </div>
      {renderQualifiedList}
    </>
  );
}

SuggestedFieldPopupString.propTypes = {
  field: PropTypes.object,
  filter: PropTypes.object,
  operator: PropTypes.string,
  isFieldNameMatch: PropTypes.bool,
  qualified: PropTypes.bool,
  qualifiedDictionary: PropTypes.array,
  valueArray: PropTypes.array,
  onOperatorChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onIsApplicableChange: PropTypes.func,
};

export default SuggestedFieldPopupString;
