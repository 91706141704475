import store from "redux/store";
import { loadAllGlobalLookups } from "api/lookup";
import { loadDataFieldGroups, loadDataFields, loadSearchFavouriteFields } from "api/datafield";
import { loadTranslations } from "api/translations";
import { setInitialLanguageStore } from "redux/localeSlice";
import { loadBookmarks, loadCountryGroups, loadLegalTeam, loadWidgets } from "api/customer";
import { getDefaultMatterData, getRequiredMatterData, getSuggestedMatterData } from "api/matter";
import { setDefaultSearch, processDataFields } from "utilities/simpleSearch";
import { loadCountryMapData, loadWorldMap3dData, loadWorldMapData } from "api/mapData";
import { setDataGroupDisplayNames, setOverrideDisplayNames } from "utilities/datafield";
import { getSavedSearches } from "api/search";
import { setSavedSearches } from "redux/simpleSearchSlice";
import { loadAllMatterTypes, loadPermissions, loadThirdPartyKeys } from "api/app";
import { signIn } from "api/user";
import { stringToBoolean } from "./boolean";

export function setCookies(authInfoLocal, isRememberMe) {
  if (isRememberMe) {
    const expiresRemember = new Date(Date.now() + 14 * 24 * 3600 * 1000).toUTCString(); // set to 14 days
    document.cookie = `u=${encodeURIComponent(authInfoLocal.username)}; path=/; expires=${expiresRemember}`;
    document.cookie = `p=${encodeURIComponent(authInfoLocal.password)}; path=/; expires=${expiresRemember}`;
  }

  const expiresJwt = new Date(Date.now() + 12 * 3600 * 1000).toUTCString(); // set to 12 hours
  document.cookie = `jwt=${encodeURIComponent(authInfoLocal.token)}; path=/; expires=${expiresJwt}`;
  document.cookie = `n=${encodeURIComponent(authInfoLocal.fullName)}; path=/; expires=${expiresJwt}`;
  document.cookie = `a=${encodeURIComponent(authInfoLocal.sysAdmin)}; path=/; expires=${expiresJwt}`;
  document.cookie = `i=${encodeURIComponent(authInfoLocal.id)}; path=/; expires=${expiresJwt}`;
  document.cookie = `c=${encodeURIComponent(JSON.stringify(authInfoLocal.customers))}; path=/; expires=${expiresJwt}`;
  document.cookie = `ca=${encodeURIComponent(JSON.stringify(authInfoLocal.custAdmins))}; path=/; expires=${expiresJwt}`;
}

export function getCookies() {
  const authInfoLocal = {
    username: "",
    password: "",
    userId: null,
    token: null,
    sysAdmin: false,
    customers: [],
    custAdmins: [],
  };

  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(";");

  //check cookies for sysAdmin
  cookieArr.forEach((cookie) => {
    const splitCookie = cookie.split("=");
    if (splitCookie[0].trim() === "a") authInfoLocal.sysAdmin = stringToBoolean(splitCookie[1]);
    if (splitCookie[0].trim() === "i") authInfoLocal.id = splitCookie[1];
    if (splitCookie[0].trim() === "jwt") authInfoLocal.token = splitCookie[1];
    if (splitCookie[0].trim() === "u") authInfoLocal.username = decodeURIComponent(splitCookie[1]);
    if (splitCookie[0].trim() === "p") authInfoLocal.password = decodeURIComponent(splitCookie[1]);
    if (splitCookie[0].trim() === "c") authInfoLocal.customers = JSON.parse(decodeURIComponent(splitCookie[1]));
    if (splitCookie[0].trim() === "ca") authInfoLocal.custAdmins = JSON.parse(decodeURIComponent(splitCookie[1]));
    if (splitCookie[0].trim() === "n") authInfoLocal.fullName = decodeURIComponent(splitCookie[1]);
  });

  return authInfoLocal;
}

export function hasValidTokenCookie() {
  const cookieArr = document.cookie.split(";");

  return cookieArr.some((cookie) => {
    const splitCookie = cookie.split("=");
    return splitCookie[0].trim() === "jwt" && splitCookie[1];
  });
}

export async function login(username, password, isRememberMe) {
  let authInfoLocal = await signIn(username, password);
  if (!authInfoLocal) {
    return null;
  }

  authInfoLocal = { ...authInfoLocal, username: username, password: password };

  setCookies(authInfoLocal, isRememberMe);

  return authInfoLocal;
}

/*****************************************
    This is the main initialisation function for all the data in the customer's browser
*****************************************/
export async function initialLoader() {
  store.dispatch(setInitialLanguageStore());
  const isSysAdmin = store.getState().app.isSysAdmin;

  const promises = [
    loadTranslations(),
    loadAllGlobalLookups(),
    loadAllMatterTypes(),
    loadDataFields(),
    loadDataFieldGroups(),
    loadSearchFavouriteFields(),
    loadLegalTeam(),
    getSavedSearches().then((savedSearches) => {
      store.dispatch(setSavedSearches(savedSearches));
      setDefaultSearch(savedSearches);
    }),
    loadThirdPartyKeys(),
    loadWorldMapData(),
    loadWorldMap3dData(),
    loadCountryMapData("us"),
    getRequiredMatterData(),
    getSuggestedMatterData(),
    getDefaultMatterData(),
  ];

  if (!isSysAdmin) {
    promises.push(loadCountryGroups(), loadBookmarks(), loadWidgets(), loadPermissions());
  }
  await Promise.all(promises);

  setDataGroupDisplayNames();
  setOverrideDisplayNames();
  processDataFields();
}
