import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addAllRecords } from "redux/reportSlice";
import { createReportRecord, getAllReports } from "api/report";
import { t } from "locale/dictionary";
import LoadingModal from "components/global/LoadingModal";
import { getAllLookupValuesForSource } from "utilities/lookup";
import ReportListGroup from "./ReportListGroup";
import { getUsersFromCustomer } from "api/security";
import { findById, hasMatchingId, idsAreEqual } from "utilities/stringAndArray";
import { FormatTypes, severities } from "utilities/constants";
import { useNavigate } from "react-router-dom";
import { displayToast } from "utilities/toast";
import Switch from "components/global/Switch";

function ReportList({ selectedPreview, isEditor, onSelect, queryTypeId, formatTypeId, onshowCreateTemplate }) {
  const appState = useSelector((state) => state.app);
  const reportState = useSelector((state) => state.report);
  const localeState = useSelector((state) => state.locale);
  const lookupState = useSelector((state) => state.lookup);
  const searchResultsState = useSelector((state) => state.searchResults).currentSearch;
  const customerState = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasCreatedRef = useRef(null);

  const [reports, setReports] = useState(null);
  const [reportsByMatterType, setReportsByMatterType] = useState(null);
  const [matterTypesAll, setMatterTypesAll] = useState(null);
  const [matterTypesInSearchResults, setMatterTypesInSearchResults] = useState(null);
  const [initialGroupsExpanded, setInitialGroupsExpanded] = useState(false);
  const [isShowingDraft, setIsShowingDraft] = useState(true);

  const selectedResults = searchResultsState?.selected;

  if (!customerState.users) getUsersFromCustomer(appState.customerId);

  useEffect(() => {
    if (selectedResults && !initialGroupsExpanded) {
      const renderData = searchResultsState.renderData.filter((rd) =>
        selectedResults.some((sr) => idsAreEqual(sr, rd.matterKey))
      );
      let matterTypesInSearchResultsLocal = [];
      renderData.forEach((rd) => {
        if (!matterTypesInSearchResultsLocal.some((mt) => mt === rd.matterTypeId))
          matterTypesInSearchResultsLocal.push(rd.matterTypeId);
      });
      setMatterTypesInSearchResults(matterTypesInSearchResultsLocal);
      if (reportsByMatterType) {
        let reportsByMatterTypeLocal = [...reportsByMatterType];
        reportsByMatterTypeLocal.forEach((group) => {
          group.isExpanded = matterTypesInSearchResultsLocal.some((mtisr) => mtisr === group.id);
        });
        setReportsByMatterType(reportsByMatterTypeLocal);
        setInitialGroupsExpanded(true);
      }
    }
  }, [searchResultsState, selectedResults, reportsByMatterType, initialGroupsExpanded]);

  useEffect(() => {
    const loadAllReports = async () => {
      let reportsLocal = await getAllReports();
      dispatch(addAllRecords({ replace: true, reports: reportsLocal }));
    };

    if (appState.customerId && !reportState.allRecordsLoaded) {
      loadAllReports();
    }
  }, [appState.customerId, reportState.allRecordsLoaded, dispatch]);

  useEffect(() => {
    let reportsLocal = reportState.records;
    if (!isShowingDraft) reportsLocal = reportsLocal.filter((r) => r.isPublished);
    if (reportsLocal) {
      if (matterTypesInSearchResults && !isEditor) {
        reportsLocal = reportsLocal.filter(
          (r) =>
            !r.matterTypeIds ||
            r.matterTypeIds.length === 0 ||
            r.matterTypeIds.some((reportMatterTypeIds) => matterTypesInSearchResults.includes(reportMatterTypeIds))
        );
      }
      if (queryTypeId) {
        reportsLocal = reportsLocal.filter((r) => idsAreEqual(r.queryTypeId, queryTypeId));
      }
      if (!isEditor) reportsLocal = reportsLocal.filter((r) => idsAreEqual(r.formatTypeId, formatTypeId));
      setReports(reportsLocal);
    }
  }, [reportState.records, queryTypeId, formatTypeId, matterTypesInSearchResults, isShowingDraft]);

  useEffect(() => {
    if (matterTypesAll) return;
    setMatterTypesAll(getAllLookupValuesForSource("MatterTypes"));
  }, [lookupState]);

  useEffect(() => {
    if (!reports || !matterTypesAll || matterTypesAll.length === 0) return;

    if (reports.length === 0) {
      setReportsByMatterType(null);
      return;
    }
    let matterTypeGroups = [
      ...matterTypesAll.map((mt) => ({
        id: mt.id,
        title: localeState.translations[mt.translationCode],
        reports: [],
        isExpanded: matterTypesInSearchResults.some((mtisr) => mtisr === mt.id),
      })),
      { id: 0, title: t("Unclassified"), reports: [], isExpanded: false },
    ];
    reports.forEach((report) => {
      // If report has no matter type, add it to "Unclassified" if not already there
      if (!report.matterTypeIds || report.matterTypeIds.length === 0) {
        const groupUnclassified = findById(matterTypeGroups, 0);
        const groupUnclassifiedReports = groupUnclassified && groupUnclassified.reports;
        if (groupUnclassifiedReports && !findById(groupUnclassifiedReports, report.id))
          groupUnclassifiedReports.push(report);
      } else {
        // Add the report to the matter type group if not already there
        report.matterTypeIds.forEach((mtid) => {
          const groupThisMatterType = findById(matterTypeGroups, mtid);
          const groupThisMatterTypeReports = groupThisMatterType && groupThisMatterType.reports;
          if (groupThisMatterTypeReports && !findById(groupThisMatterTypeReports, report.id))
            groupThisMatterTypeReports.push(report);
        });
      }
    });
    // Remove deleted reports
    matterTypeGroups.forEach((group) => {
      group.reports = group.reports.filter((gReport) => hasMatchingId(reports, gReport.id));
    });

    // Remove groups with no reports
    matterTypeGroups = matterTypeGroups.filter((group) => group.reports.length > 0);

    // Remove groups that do not match search result matter types
    if (matterTypesInSearchResults && !isEditor) {
      matterTypeGroups = matterTypeGroups.filter(
        (group) => idsAreEqual(group.id, 0) || matterTypesInSearchResults.includes(group.id)
      );
    }

    // Apply existing expanded state to the new list of matterTypeGroups after refresh (deletion, copying or adding of new reports)
    if (reportsByMatterType) {
      let reportsByMatterTypeExisting = [...reportsByMatterType];
      reportsByMatterTypeExisting.forEach((groupExisting) => {
        const groupNew = findById(matterTypeGroups, groupExisting.id);
        if (groupNew) groupNew.isExpanded = groupExisting.isExpanded;
      });
    }
    console.log("matterTypeGroups", matterTypeGroups);

    setReportsByMatterType(matterTypeGroups);
  }, [reports, matterTypesAll]);

  const handleGroupExpanderClick = (groupId) => {
    let groupsLocal = [...reportsByMatterType];
    let groupToUpdate = findById(groupsLocal, groupId);
    groupToUpdate.isExpanded = !groupToUpdate.isExpanded;
    setReportsByMatterType(groupsLocal);
  };

  const handleCreateNewReport = async () => {
    if (hasCreatedRef.current) return;
    hasCreatedRef.current = true;

    const nameLocal = "New Report";
    const descriptionLocal = "New Report";
    const queryTypeIdLocal = 1;
    const matterTypeIdsLocal = [60];
    const formatTypeIdLocal = FormatTypes.LIST;

    const response = await createReportRecord(
      nameLocal,
      descriptionLocal,
      queryTypeIdLocal,
      matterTypeIdsLocal,
      formatTypeIdLocal
    );
    if (response?.id) {
      navigate(`/${appState.customerId}/reportmenu/reporteditor/${response.id}`);
    } else displayToast("Error creating report", severities.ERROR);
  };

  const handleCreateNewMailMerge = async () => {
    if (hasCreatedRef.current) return;
    hasCreatedRef.current = true;

    const nameLocal = "New Mail Merge";
    const descriptionLocal = "New Mail Merge";
    const queryTypeIdLocal = 1;
    const matterTypeIdsLocal = [60];
    const formatTypeIdLocal = FormatTypes.MAILMERGE;

    const response = await createReportRecord(
      nameLocal,
      descriptionLocal,
      queryTypeIdLocal,
      matterTypeIdsLocal,
      formatTypeIdLocal
    );
    if (response?.id) {
      navigate(`/${appState.customerId}/reportmenu/reporteditor/${response.id}`);
    } else displayToast("Error creating report", severities.ERROR);
  };

  return (
    <div className="report-menu__section report-menu__section">
      <div className="report-menu__section-header">
        <div className="header__title">{t("Available Reports")}</div>
        <div className="header__toolbar">
          <div className="header-bar">
            <Switch
              text={t("Show Draft Reports")}
              isOn={isShowingDraft}
              onSwitchChange={() => setIsShowingDraft(!isShowingDraft)}
            />
          </div>
          {isEditor && (
            <>
              <button onClick={handleCreateNewMailMerge}>{t("Create Mail Merge Template")}</button>
              <button onClick={handleCreateNewReport}>{t("Create New Report Template")}</button>
            </>
          )}
        </div>
      </div>
      <div className="report-menu__report-list">
        {reports !== null ? (
          reportsByMatterType &&
          reportsByMatterType.map((group) => (
            <ReportListGroup
              key={group.id}
              group={group}
              handleGroupExpanderClick={handleGroupExpanderClick}
              isEditor={isEditor}
              onSelect={onSelect}
              formatTypeId={formatTypeId}
            />
          ))
        ) : (
          <LoadingModal />
        )}
      </div>
    </div>
  );
}

ReportList.propTypes = {
  selectedPreview: PropTypes.object,
  isEditor: PropTypes.bool,
  onSelect: PropTypes.func,
  queryTypeId: PropTypes.number,
  formatTypeId: PropTypes.number,
  onshowCreateTemplate: PropTypes.func,
};

export default ReportList;
