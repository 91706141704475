import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { idsAreEqual } from "utilities/stringAndArray";

//Component for the display of a horizontal or vertical radio button group
export default function RadioGroup({
  groupName,
  onRadioChange,
  optionsArray,
  defaultSelectedOptionId,
  selectedOptionIdExternal,
  isButtonStyle,
  isDisabled = false,
  isVertical = false,
}) {
  const [selectedOptionId, setSelectedOptionId] = useState();

  useEffect(() => {
    //get default selected radio
    if (optionsArray?.length > 0) {
      setSelectedOptionId(defaultSelectedOptionId);
    }
  }, [defaultSelectedOptionId, optionsArray]);

  useEffect(() => {
    //get default selected radio
    if (optionsArray?.length > 0) {
      setSelectedOptionId(selectedOptionIdExternal);
    }
  }, [selectedOptionIdExternal, optionsArray]);

  const handleRadioChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedOptionId(value);
    onRadioChange(value);
  };

  let className = isButtonStyle ? "button-radio-group" : "radio-group";
  if (isVertical) className = `${className} ${className}--vertical`;

  //MAIN RENDER
  return (
    <div className={className}>
      {optionsArray?.length > 0 &&
        optionsArray.map((option) => (
          <div className="radio-item" key={option.id}>
            <input
              id={option.id}
              type="radio"
              name={groupName}
              value={option?.id}
              checked={idsAreEqual(option?.id, selectedOptionId)}
              onChange={(e) => handleRadioChange(e)}
              disabled={isDisabled}
            />
            <label htmlFor={option.id} onClick={(e) => handleRadioChange(e)}>
              {option?.displayValue}
            </label>
          </div>
        ))}
    </div>
  );
}

RadioGroup.propTypes = {
  groupName: PropTypes.string,
  optionsArray: PropTypes.array,
  onRadioChange: PropTypes.func,
  defaultSelectedOptionId: PropTypes.number,
  selectedOptionIdExternal: PropTypes.number,
  isVertical: PropTypes.bool,
  isButtonStyle: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
