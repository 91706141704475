import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fieldTextValueChange,
  inputTextValueChange,
  labelSelectedChange,
  popupVisibleChange,
  searchValueChange,
  setSavedSearchLoadedId,
  setKeyboardFocusedSelection,
  setPopupWholeSection,
  clearAllFilters,
  filtersPopupVisibleChange,
} from "redux/simpleSearchSlice";
import { simpleSearchKeyboardFocusedPopup } from "utilities/constants";
import PopupHeader from "components/global/PopupHeader";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { focusFirstTabbedChild } from "utilities/dom";
import SuggestedFieldPopupContent from "./SuggestedFieldPopupContent";
import { ImBin } from "react-icons/im";

/*  Suggested Field Popup Container. This displays to the right-hand-side when the user clicks on a suggested field match label 
    and contains one or more Suggested Field Popup Content elements  
 */
function SuggestedFieldPopupContainer({
  fields,
  sectionDisplayName,
  parent,
  isFieldNameMatch,
  filters,
  title,
  selectedFilter,
}) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const [isApplicable, setIsApplicable] = useState(false); //filter ? false : field.dataType === labelTypes.BOOLEAN);
  const [isApplying, setIsApplying] = useState(false);
  const [hasApplied, setHasApplied] = useState([]);

  // Initialize local state for expanded filters, set selected filter to expanded
  const [expandedFields, setExpandedFields] = useState(
    fields.reduce((acc, field) => {
      acc[field.fieldName] = field.fieldName === selectedFilter?.fieldName;
      return acc;
    }, {})
  );

  useEffect(() => {
    if (simpleSearch.isKeyboardFocusedSelection === simpleSearchKeyboardFocusedPopup.RHS && containerRef.current) {
      focusFirstTabbedChild(containerRef.current);
    }
  }, [simpleSearch.isKeyboardFocusedSelection]);

  // useEffect for checking when all elements have completed applying, close the RHS popup and reset hasApplied to empty
  useEffect(() => {
    if (hasApplied.length > 0) {
      const allApplied = hasApplied.every((field) => field.hasApplied);
      if (allApplied) {
        dispatch(labelSelectedChange(false));
        dispatch(searchValueChange({ text: "", locale: "", fieldsQualifiedValues: [] }));
        dispatch(inputTextValueChange(""));
        dispatch(fieldTextValueChange(""));
        //dispatch(setSavedSearchLoadedId(null));
        dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.SEARCH_PANEL));

        // Close the main popup and re-focus the search input control
        document.getElementsByClassName("simple-search__input")[0].focus();
        dispatch(popupVisibleChange(false));
        dispatch(filtersPopupVisibleChange(false));
        if (parent === "Section") {
          dispatch(setPopupWholeSection(null));
        }

        setIsApplying(false);
        setHasApplied([]);
      }
    }
  }, [hasApplied]);

  // Function to toggle the expanded state of a filter
  const toggleFieldExpansion = (fieldName) => {
    setExpandedFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  // Function to handle expand all click
  const handleExpandAllClick = () => {
    setExpandedFields((prev) => {
      const allExpanded = Object.values(prev).every((isExpanded) => isExpanded);
      const newExpandedState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = !allExpanded;
        return acc;
      }, {});
      return newExpandedState;
    });
  };

  const handleCloseClick = () => {
    if (parent === "Section") {
      dispatch(setPopupWholeSection(null));
    }
    dispatch(labelSelectedChange(false));
    dispatch(fieldTextValueChange(""));
  };

  const handleApplyClearClick = async () => {
    if (!isApplicable || isApplying) return;
    setIsApplying(true);
    setHasApplied(fields.map((field) => ({ fieldName: field.fieldName, hasApplied: false })));
  };

  const handleClearAllFiltersClick = () => {
    dispatch(clearAllFilters());
    dispatch(setSavedSearchLoadedId(null));
  };

  // update the hasApplied array with the fieldName and hasApplied value when a child component sends back that that it has completed applying
  const handleHasAppliedField = (fieldName) => {
    if (!isApplying) return;
    const hasAppliedLocal = hasApplied;
    hasAppliedLocal.forEach((field) => {
      if (field.fieldName === fieldName) {
        field.hasApplied = true;
        setHasApplied(hasAppliedLocal);
      }
    });
  };

  const handleIsApplicableChange = (isApplicableIn) => {
    setIsApplicable(isApplicableIn);
  };

  return (
    (parent === simpleSearch.labelSelected.clickSource || parent === "Section") && (
      <>
        {!filters && (
          <PopupHeader title={fields.length === 1 ? fields[0].displayName : title} onClose={handleCloseClick} />
        )}
        <div className="popup__field-container">
          {appState.newFiltersMode && fields.length > 1 && (
            <div className="popup__all-operations">
              <div className="all-operations__item" onClick={handleExpandAllClick}>
                {t("Expand/Collapse All")}
              </div>
              <div className="all-operations__item" onClick={handleClearAllFiltersClick}>
                Clear All&nbsp;
                <span className="delete">
                  <ImBin />
                </span>
              </div>
            </div>
          )}
          <div className="popup__fields" ref={containerRef}>
            {fields.map((field) => {
              const filter = filters?.find((filter) => filter.fieldName === field.fieldName);
              const isExpanded = expandedFields[field.fieldName];
              return (
                <SuggestedFieldPopupContent
                  key={field.fieldName}
                  field={field}
                  sectionDisplayName={sectionDisplayName}
                  parent={parent}
                  isFieldNameMatch={isFieldNameMatch}
                  isExpanded={isExpanded}
                  filter={filter}
                  handleIsApplicableChangeParent={handleIsApplicableChange}
                  isApplying={isApplying}
                  handleHasAppliedField={handleHasAppliedField}
                  handleForceApply={handleApplyClearClick}
                  handleTitleClick={() => toggleFieldExpansion(field.fieldName)}
                />
              );
            })}
          </div>
          <div className="popup__footer">
            <button className={isApplicable ? "" : "disabled"} onClick={handleApplyClearClick} tabIndex={0}>
              {t(`Apply Filter${fields?.length > 1 ? "s" : ""}`)}
            </button>
          </div>
        </div>
      </>
    )
  );
}

SuggestedFieldPopupContainer.propTypes = {
  fields: PropTypes.array,
  sectionDisplayName: PropTypes.string,
  parent: PropTypes.string,
  isFieldNameMatch: PropTypes.bool,
  filters: PropTypes.array,
  title: PropTypes.string,
  handleCloseClickSection: PropTypes.func,
  selectedFilter: PropTypes.object,
};

export default SuggestedFieldPopupContainer;
