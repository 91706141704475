// DataField API calls

import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";
import { setDataFieldGroups, setDataFields } from "redux/dataFieldSlice";
import { setFavouriteFields } from "redux/simpleSearchSlice";
import { setOverrideDisplayNames } from "utilities/datafield";
import { reloadFieldsAndFilterOnMatterType } from "utilities/simpleSearch";
import { setDataFieldsLoaded } from "redux/appSlice";

export async function loadDataFields() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const responseDataFields = await fetchWrapper.get(`customers/${customerId}/datafields`);
  store.dispatch(setDataFields(responseDataFields));
  store.dispatch(setDataFieldsLoaded());
}

export async function loadSearchFavouriteFields() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const responseFavouriteFields = await fetchWrapper.get(`customers/${customerId}/searchfavouritefields`);
  store.dispatch(setFavouriteFields(responseFavouriteFields));
}

export async function loadDataFieldGroups() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const dataFieldGroups = await fetchWrapper.get(`customers/${customerId}/datafieldgroups`);
  store.dispatch(setDataFieldGroups(dataFieldGroups));
}

export async function addToFavouriteFields(dataFieldName) {
  const state = store.getState();
  const customerId = state.app.customerId;
  await fetchWrapper.post(`customers/${customerId}/topdatafields/${dataFieldName}`, "");
  reinitializeDataFields();
}

export async function removeFromFavouriteFields(dataFieldName) {
  const state = store.getState();
  const customerId = state.app.customerId;
  await fetchWrapper.delete(`customers/${customerId}/topdatafields/${dataFieldName}`);
  reinitializeDataFields();
}

const reinitializeDataFields = async () => {
  await loadDataFields();
  await loadSearchFavouriteFields();
  setOverrideDisplayNames();
  reloadFieldsAndFilterOnMatterType();
};
