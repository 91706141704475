import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SuggestedFieldPopupContainer from "./SuggestedFieldPopupContainer";

// Bridge to handle the standard field popup container from the new filters component
function FiltersNewPopup({ inputRef, selectedFilter }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const [fields, setFields] = useState(simpleSearch.filters);

  useEffect(() => {
    // set fields by finding each within the simpleSearch.fields array based on simpleSearch.filters.fieldName
    setFields(
      simpleSearch.filters.map((filter) => {
        return simpleSearch.fields.find((field) => field.fieldName === filter.fieldName);
      })
    );
  }, [simpleSearch.filters]);

  return (
    <div className="filters-new__popup" ref={inputRef}>
      <div className="popup__header">
        <div className="header__title">Search Filters</div>
      </div>
      {/* <div className="popup__body"> */}
      <SuggestedFieldPopupContainer
        fields={fields}
        filters={simpleSearch.filters}
        parent="Section"
        title={"Selected Filters"}
        selectedFilter={selectedFilter}
      />
      {/* </div> */}
    </div>
  );
}

FiltersNewPopup.propTypes = {
  inputRef: PropTypes.object,
  selectedFilter: PropTypes.object,
};

export default FiltersNewPopup;
