import { useState } from "react";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";

function CustomerSelection({ customers, onCustomerSelected }) {
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const handleCustomerSelected = (customerId) => {
    setSelectedCustomerId(customerId);
    onCustomerSelected(customerId);
  };

  const customerList = customers.map((c) => ({ id: c.customerId, displayValue: c.customerName }));

  return (
    <>
      <div className="login__row">
        Your login has access to multiple customers. Please select the customer you wish to use for this session:
      </div>
      <div className="login__row">
        <SuperSelect
          options={customerList}
          onChange={(optionId) => handleCustomerSelected(optionId)}
          selectedOptionId={selectedCustomerId}
          value={selectedCustomerId}
        />
      </div>
    </>
  );
}

CustomerSelection.propTypes = {
  customers: PropTypes.array,
  onCustomerSelected: PropTypes.func,
};

export default CustomerSelection;
