import Modal from "components/global/Modal";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { generateMatter } from "api/project";
import { clearResults, setViewTypeSearchResults } from "redux/searchResultsSlice";
import { FormatTypes, searchViewTypes } from "utilities/constants";
import { searchAndSetResults } from "api/search";
import { Link } from "react-router-dom";
import Spinner from "components/global/Spinner";

// Shared component for generating matters at the close of the project process
function ProjectGenerateMatters({ numRecords }) {
  const appState = useSelector((state) => state.app);
  const projectState = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const project = projectState?.project;

  const [hasCreatedMatters, setHasCreatedMatters] = useState(false);
  const [isCreatingMatters, setIsCreatingMatters] = useState(false);

  const handleCreateRecordsClick = () => {
    generateMatter(project.id).then((response) => {
      if (response) {
        setHasCreatedMatters(true);
        dispatch(clearResults());
        dispatch(setViewTypeSearchResults(searchViewTypes.MAIN));
        const queryObject = {
          formatType: FormatTypes.LIST,
          queryType: 1, // MATTER
          searchFilters: [
            {
              fieldName: "project_ProjectId",
              operator: "=",
              valueArray: [project.id],
            },
          ],
        };
        searchAndSetResults(queryObject, searchViewTypes.MAIN);
        setIsCreatingMatters(false);
      }
    });
    setIsCreatingMatters(true);
  };

  const recordString = numRecords === 1 ? "record" : "records";

  return (
    <div className="batch-import__create-records">
      <Modal
        buttonLabel="Create Record(s)"
        title="Create Records"
        closeOnly={hasCreatedMatters}
        leaveOpen={true}
        onConfirm={handleCreateRecordsClick}
      >
        {hasCreatedMatters ? (
          <>
            <p>Records have been created successfully!</p>
            <p>&nbsp;</p>
            <Link to={`${appState.customerId}/results/`}>View created records</Link>
            <p>&nbsp;</p>
          </>
        ) : isCreatingMatters ? (
          <>
            <Spinner />
            <p>
              Creating {numRecords} {recordString}... Please wait...
            </p>
          </>
        ) : (
          <>
            <p>
              Are you sure you want to create the selected {numRecords} {recordString}
            </p>
            <p>&nbsp;</p>
            <p>This action cannot be undone!</p>
            <p>&nbsp;</p>
          </>
        )}
      </Modal>
    </div>
  );
}

export default ProjectGenerateMatters;
