import { Routes, Route } from "react-router";
import Authentication from "./Authentication";
import NewUser from "./NewUser";

// Routing for authentications
export default function AuthenticationRoutes() {
  return (
    <Routes>
      <Route exact path="/new-user" element={<NewUser />} />
      <Route path="/:customerId/*" element={<Authentication />} />
      <Route path="/customers/:customerId/*" element={<Authentication />} />
      <Route path="/customers" element={<Authentication />} />
      <Route path="/" element={<Authentication />} /> {/* Root route */}
    </Routes>
  );
}
