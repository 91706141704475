import { useDispatch, useSelector } from "react-redux";
import { getListDisplayValue, getLookupValueBySource } from "utilities/lookup";
import CountryFlagContainer from "components/global/CountryFlagContainer";
import MatterRecordPageSelector from "./PageSelector";
import { MdBookmark, MdBookmarkBorder, MdCompare } from "react-icons/md";
import Tooltip from "components/global/Tooltip";
import { setComparing } from "redux/matterSlice";
import PropTypes from "prop-types";
import { FormatTypes, matterTypes, referenceTypes, validationStates } from "utilities/constants";
import MatterRecordHeaderCell from "./HeaderCell";
import MatterRecordHeaderThumbnail from "./HeaderThumbnail";
import { clearBookmark, saveBookmark } from "api/customer";
import CreateRecordShareLink from "./CreateRecordShareLink";
import RecordCopyModal from "./RecordCopyModal";
import { t } from "locale/dictionary";
import { headerDefinitions } from "utilities/headerDefinitions";
import { idsAreEqual } from "utilities/stringAndArray";
import Delete from "components/global/Delete";
import { useNavigate } from "react-router-dom";
import { deleteMatterRecord } from "api/matter";
import { removeSearchResult } from "redux/searchResultsSlice";
import { getLookupValueForField } from "utilities/datafield";
import { getCountryNameFromIsoCode } from "utilities/countries";
import { canCreateMatterObject, canCreateMatterRecord, canDeleteMatterRecord } from "utilities/permission";

// Container Component for the matter record header block
function MatterRecordHeader({ record }) {
  const appState = useSelector((state) => state.app);
  const matter = useSelector((state) => state.matter);
  const bookmarks = useSelector((state) => state.customer).bookmarks;
  const bookmark = bookmarks?.find((bookmark) => idsAreEqual(bookmark.matterId, record.id));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /*
  link types used in headers
  1 - Agent
  ?? 6 - Client
  18 - Other Party
  21 - Owner  
  31 - Service Provider
  */

  const handleRecordDelete = async () => {
    await deleteMatterRecord(record.id);
    dispatch(removeSearchResult(record.id));
    navigate(`/${appState.customerId}/results`, { replace: true });
  };

  const thisMatterTypeHeaderDef = headerDefinitions.find((hd) =>
    idsAreEqual(hd.matterTypeId, record.matter_MatterTypeId)
  );
  const matterTypeClassNameBase = "record-header__title-item";
  let matterTypeClassName = matterTypeClassNameBase;

  if (thisMatterTypeHeaderDef && !thisMatterTypeHeaderDef.hasImage)
    matterTypeClassName = `${matterTypeClassNameBase} ${matterTypeClassNameBase}--no-image`;

  const renderTitleBar = (
    <div className="record-header__title-bar">
      <div className={matterTypeClassName}>
        <strong>{getLookupValueBySource("MatterTypes", record.matter_MatterTypeId)}</strong>
      </div>
      <div className="record-header__title-item">{record.matter_Name}</div>
    </div>
  );

  let renderTextSection = null;
  let renderImage = <MatterRecordHeaderThumbnail record={record} />;
  if (idsAreEqual(record.matter_MatterTypeId, matterTypes.COMPANY)) {
    // COMPANY RECORDS
    const fileReferenceObject = record?.references?.find((ref) =>
      idsAreEqual(ref.matterReference_ReferenceTypeId, referenceTypes.FILE)
    );
    const fileReference = fileReferenceObject
      ? fileReferenceObject.matterReference_ReferenceNumber
      : t("No File Reference");

    const countriesRender = record?.countries?.map((isoCode) => {
      return <CountryFlagContainer key={isoCode} isoCode={isoCode} />;
    });

    renderTextSection = (
      <div className="record-header__text-section">
        {record && (
          <div className="record-header__company">
            <div className="record-header__company-name">
              <div className="record-header__title-item">
                <strong>{record.matter_Name}</strong>&nbsp;
                <span className="record-header__matter-type-sub">(Company)</span>
              </div>
              <div className="record-header__company-address">
                {record.matter_Address1 ? record.matter_Address1 + "," : ""}
                {record.matter_Address2 ? record.matter_Address2 + ", " : ""}
                {record.matter_Address3 ? record.matter_Address3 + ", " : ""}
                {record.matter_City ? record.matter_City + ", " : ""}
                {record.matter_State ? record.matter_State + ", " : ""}
                {record.matter_PostalCode ? record.matter_PostalCode : ""}
              </div>
            </div>
            <div className="record-header__references">{fileReference}</div>
            <div>{countriesRender}</div>
          </div>
        )}
      </div>
    );
  } else {
    // EVERYTHING ELSE (NOT COMPANIES)
    const headerDefThisMatterType = headerDefinitions.find((hd) =>
      idsAreEqual(hd.matterTypeId, record.matter_MatterTypeId)
    );
    const headerDefRender = headerDefThisMatterType?.cellDefinitions.map((cd, index) => (
      <MatterRecordHeaderCell
        key={`${cd.cellType}${cd.linkTypeId}${cd.referenceTypeId}${cd.tableName}${cd.fieldName}${cd.legalTypeId}}`}
        record={record}
        cellDefinition={cd}
      />
    ));

    if (!headerDefThisMatterType?.hasImage) renderImage = null;

    renderTextSection = (
      <div className="record-header__text-section">
        {renderTitleBar}
        <div className="record-header__text-row">{headerDefRender}</div>
      </div>
    );
  }

  // Go through all the sections, fields and validationStates within and note the number of errors and successes
  const renderValidationSummary = () => {
    // iteration through the record.sections.fields.validationStates in turn
    // and count the number of errors and successes
    // and render the summary
    let numErrors = 0;
    let numSuccess = 0;
    record?.sections?.forEach((section) => {
      section?.fields?.forEach((field) => {
        field?.validationStates?.forEach((validationState) => {
          if (validationState.state === validationStates.ERROR) {
            numErrors++;
          } else if (validationState.state === validationStates.SUCCESS) {
            numSuccess++;
          }
        });
      });
    });

    return (
      <>
        {numSuccess > 0 && (
          <div className="validation-summary-item validation-summary-item--success">
            {t("Fields successfully updated")}: {numSuccess}
          </div>
        )}
        {numErrors > 0 && (
          <div className="validation-summary-item validation-summary-item--error">
            {t("Fields with errors")}: {numErrors}
          </div>
        )}
      </>
    );
  };

  const deleteMessage = `Are you sure you want to delete the ${getLookupValueForField(
    "matter_MatterTypeId",
    record.matter_MatterTypeId
  )} "${record.matter_Name}" in ${record.countries
    .map((country) => getCountryNameFromIsoCode(country))
    .join(", ")}? All contained data will be destroyed as well as all references of this in other records.`;

  const renderActions = (
    <div className="record-header__actions">
      <div className="record-header__page-selector-list">
        <MatterRecordPageSelector page="Matter" text="Matter" />
        <MatterRecordPageSelector page="Linked" text="Linked Records" />
        <MatterRecordPageSelector page="History" text="History" />
      </div>
      <div className="record-header__validation-summary">{renderValidationSummary()}</div>
      <div className="record-header__action-icon-list">
        {canCreateMatterRecord(record.matter_MatterTypeId, record.matter_AccountId) && (
          <RecordCopyModal record={record} />
        )}
        {matter.records.length > 1 && !matter.isComparing && (
          <div
            className="record-header__action-icon"
            onClick={() => dispatch(setComparing({ isComparing: true, numberComparing: 2 }))}
          >
            <Tooltip content="Compare">
              <MdCompare />
            </Tooltip>
          </div>
        )}
        <CreateRecordShareLink recordId={record.id} />
        <div
          className="record-header__action-icon"
          onClick={() => (bookmark ? clearBookmark(bookmark) : saveBookmark(record.id))}
        >
          <Tooltip content="Bookmark">
            {bookmark ? (
              <div className="bookmarked flex-row-center">
                <MdBookmark />
              </div>
            ) : (
              <MdBookmarkBorder />
            )}
          </Tooltip>
        </div>
        {canDeleteMatterRecord(record.matter_MatterTypeId, record.matter_AccountId) && (
          <div className="record-header__action-icon">
            <Delete title message={deleteMessage} showTrashIconInstead={true} onConfirm={handleRecordDelete} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="matter-record__header">
      <div className="record-header__info">
        {renderImage}
        {renderTextSection}
      </div>
      {renderActions}
      {/* {showIsDeletedModal && <Modal title="Record Deleted" onClose={() => setShowIsDeletedModal(false)} />} */}
    </div>
  );
}

MatterRecordHeader.propTypes = {
  record: PropTypes.object,
};

export default MatterRecordHeader;
