import { useEffect, useState } from "react";
import useComponentVisible from "components/global/useComponentVisible";
import SavedSearchesPopup from "components/header/SavedSearchesPopup";
import { getLookupValueBySource } from "utilities/lookup";
import PropTypes from "prop-types";
import { widgetTypes } from "utilities/constants";
import Bookmarks from "./Bookmarks";
import Chart from "./Chart";
import Map from "./Map";
import MruRecords from "./MruRecords";
import MyDiary from "./MyDiary";
import Switch from "components/global/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setWidgetDirectSearch, setZoomedWidgetId } from "redux/homeSlice";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { FormatTypes } from "utilities/constants";
import { useNavigate } from "react-router-dom";
import { search } from "api/search";
import { parseSearchResponse } from "utilities/searchResults";
import { setResults } from "redux/searchResultsSlice";
import { mergeFilters, replaceSimpleSearchFilters } from "utilities/simpleSearch";
import { ImEnlarge, ImShrink } from "react-icons/im";
import { findById, idsAreEqual } from "utilities/stringAndArray";
import { PiLightning, PiLightningSlash } from "react-icons/pi";
import { deepCompare } from "utilities/object";

// Generic holder for various content on the Home Screen
function Widget({ widget }) {
  const appState = useSelector((state) => state.app);
  const homeState = useSelector((state) => state.home);
  const simpleSearchFilters = useSelector((state) => state.simpleSearch).currentSearch?.filters;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [isDirectSearch, setIsDirectSearch] = useState(true);
  const [isInSync, setIsInSync] = useState(false);

  const [refSavedPopup, showSavedPopup, setShowSavedPopup] = useComponentVisible(false);

  useEffect(() => {
    const widgetDirectSearch = findById(homeState.widgetDirectSearches, widget.id);
    if (widgetDirectSearch && widgetDirectSearch.isDirectSearch !== isDirectSearch)
      setIsDirectSearch(widgetDirectSearch ? widgetDirectSearch.isDirectSearch : false);
  }, [homeState.widgetDirectSearches]);

  useEffect(() => {
    if (widget.title) {
      setTitle(widget.title);
    } else {
      setTitle(getLookupValueBySource("WidgetTypes", widget.widgetTypeId));
    }
  }, [widget.title, widget.widgetTypeId]);

  useEffect(() => {
    if (widget?.filters && simpleSearchFilters?.length > 0) {
      //if (widget.id === 7) {
      const isInSyncLocal = deepCompare(widget?.filters, simpleSearchFilters);
      setIsInSync(isInSyncLocal);

      //   console.log(
      //     widget.id,
      //     "widget filters fieldnames, operator and valueArray: ",
      //     widget.filters.map((f) => f.fieldName).join(", "),
      //     widget.filters.map((f) => f.operator).join(", "),
      //     widget.filters.map((f) => f?.valueArray?.join(", "))
      //   );
      //   console.log(
      //     widget.id,
      //     "simpleSearchFilters filters fieldnames and valueArray: ",
      //     simpleSearchFilters.map((f) => f.fieldName).join(", "),
      //     simpleSearchFilters.map((f) => f.operator).join(", "),
      //     simpleSearchFilters.map((f) => f?.valueArray?.join(", "))
      //   );
      // }
    }
  }, [widget?.filters, simpleSearchFilters]);

  const handleTitleClick = () => {
    switch (widget.widgetTypeId) {
      case widgetTypes.FAVOURITE_SEARCHES:
        setShowSavedPopup(true);
        break;
      default:
        break;
    }
  };

  const handleZoomedWidgetClick = () => {
    dispatch(setZoomedWidgetId(idsAreEqual(homeState.zoomedWidgetId, widget.id) ? null : widget.id));
  };

  const handleToggleDirectSearch = () => {
    dispatch(setWidgetDirectSearch({ id: widget.id, isDirectSearch: !isDirectSearch }));
    setIsDirectSearch(!isDirectSearch);
  };

  const handleSearchClick = async (drillFilters) => {
    const searchFilters = mergeFilters(widget.filters, drillFilters);

    replaceSimpleSearchFilters(searchFilters);
    if (isDirectSearch) {
      let query = [];
      searchFilters.forEach((f) => {
        const fieldName = f.fieldName;
        const operator = f.operator;
        const qualified = f.qualified;
        const valueArray = f.valueArray;
        query.push({ fieldName, operator, valueArray, qualified });
      });

      const queryObject = {
        queryType: 1, // MATTER
        formatType: FormatTypes.LIST,
        searchFilters: query,
      };

      const response = await search(queryObject);
      if (!response || !response.header || !response.header.dataFieldNames) {
        console.log("Incomplete response on API Search. Response details: " + response);
        return;
      }

      const cols = parseSearchResponse(response);
      if (cols[0]?.data?.length === 1) navigate(`/matter/${cols[0]?.data[0]?.matterKey}`, { replace: false });
      else {
        navigate(`/${appState.customerId}/results`, { replace: false });
        dispatch(setResults({ cols, logId: response.logId }));
      }
    }
  };

  let renderContent = "?";
  const homeClassNames = ["home__widget"];
  let showDirectSearch = false;
  switch (widget.widgetTypeId) {
    case widgetTypes.BOOKMARKS:
      renderContent = <Bookmarks />;
      break;
    case widgetTypes.CHART:
      homeClassNames.push("home__widget-pivot");
      renderContent = <Chart widget={widget} onSearchClick={handleSearchClick} />;
      showDirectSearch = true;
      break;
    case widgetTypes.DIARY:
      renderContent = <MyDiary />;
      break;
    // case widgetTypes.FAVOURITE_SEARCHES:
    //   renderContent = <PinnedSearches isDirectSearch={isDirectSearch} />;
    //   showDirectSearch = true;
    //   break;
    case widgetTypes.MAP:
      homeClassNames.push("home__widget-map");
      renderContent = <Map widget={widget} onSearchClick={handleSearchClick} />;
      showDirectSearch = true;
      break;
    case widgetTypes.MRU:
      renderContent = <MruRecords />;
      break;
    default:
      renderContent = "NOT IMPLEMENTED";
      break;
  }

  const isWidgetZoomed = idsAreEqual(widget.id, homeState.zoomedWidgetId);

  if (isWidgetZoomed) homeClassNames.push("home__widget--zoomed");
  const toolTipText = isDirectSearch
    ? "Turn off Direct Search - clicks from this widget will then add to existing search"
    : "Turn on Direct Search - clicks from this widget will then navigate directly to record details";

  return (
    <>
      <div className={homeClassNames.join(" ")}>
        <div className="widget__header" onClick={handleTitleClick}>
          <div className="widget__header-title">{title}</div>
          <div className="widget__header-icons">
            {/* <div className="widget__header-icon">{`ID: ` + widget.id}</div>
            <div className="widget__header-icon">{isInSync ? <PiLightning /> : <PiLightningSlash />}</div> */}
            {widget.widgetTypeId !== widgetTypes.DIARY && (
              <div className="widget__header-icon" onClick={handleZoomedWidgetClick}>
                {isWidgetZoomed ? <ImShrink /> : <ImEnlarge />}
              </div>
            )}
            {showDirectSearch && (
              <Tooltip className="widget__header-icon" content={t(toolTipText)}>
                <Switch isOn={isDirectSearch} onSwitchChange={handleToggleDirectSearch} />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="widget__body">{renderContent}</div>
      </div>
      {showSavedPopup && <SavedSearchesPopup inputRef={refSavedPopup} />}
    </>
  );
}

Widget.propTypes = {
  title: PropTypes.string,
  widget: PropTypes.object,
};

export default Widget;
