import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FaAngleDown, FaFileAlt, FaCalendarDay, FaPaperclip, FaEye, FaComment, FaUser, FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { queryTypeChange } from "redux/simpleSearchSlice";
import { getAllLookupValuesForSource } from "utilities/lookup";
import useComponentVisible from "components/global/useComponentVisible";
import { processDataFields } from "utilities/simpleSearch";
import { t } from "locale/dictionary";

// Container for the selected filter box
export default function SearchTypeDropdown() {
  const [refPopup, isPopupVisible, setIsPopupVisible] = useComponentVisible(false);

  const dispatch = useDispatch();

  const simpleSearchState = useSelector((state) => state.simpleSearch).currentSearch;

  const queryTypeCode = simpleSearchState?.queryType?.code;

  const queryTypes = getAllLookupValuesForSource("QueryTypes");

  const onQueryTypeChange = async (qt) => {
    dispatch(queryTypeChange(qt));
  };

  useEffect(() => {
    simpleSearchState.queryType &&
      simpleSearchState.fields &&
      simpleSearchState.fields.dataFields &&
      simpleSearchState.fields.dataFields.length > 0 &&
      processDataFields();
  }, [simpleSearchState.queryType]);

  const getIconForQueryType = (qt) => {
    let icon = <FaFileAlt />;
    switch (qt) {
      case "MATTER":
        icon = <FaFileAlt />;
        break;
      case "DIARY":
        icon = <FaCalendarDay />;
        break;
      case "DOCUMENTS":
        icon = <FaPaperclip />;
        break;
      case "MATTERAUDIT":
        icon = <FaEye />;
        break;
      case "COMMENTS":
        icon = <FaComment />;
        break;
      case "CONTACTS":
        icon = <FaUser />;
        break;
      default:
        break;
    }
    return icon;
  };

  const renderDropdownItems = () => {
    const displayExclusionList = ["USPTO", "CORSEARCH", "BATCHIMPORT"];
    const queryTypesFiltered = queryTypes?.filter((qt) => !displayExclusionList.includes(qt.code));
    return queryTypesFiltered.map((qt) => (
      <p key={qt.code} onClick={() => onQueryTypeChange(qt)}>
        {getIconForQueryType(qt.code)}
        &nbsp;{t("Search")}&nbsp;{qt.displayValue}
      </p>
    ));
  };

  const buttonClassBase = "simple-search__search-type-dropdown";
  let buttonClass = buttonClassBase;
  if (isPopupVisible) buttonClass += ` ${buttonClassBase}--popup-visible`;

  return (
    <div className={buttonClass} onClick={() => setIsPopupVisible(!isPopupVisible)} ref={refPopup}>
      <FaAngleDown />
      <FaSearch />
      &nbsp;{getIconForQueryType(queryTypeCode)}
      {isPopupVisible && <div className="search-type-dropdown__popup">{renderDropdownItems()}</div>}
    </div>
  );
}
