// Utility functions for datafields
import { t } from "locale/dictionary";
import { setDataFieldGroups, setTranslatedOverrides } from "redux/dataFieldSlice";
import store from "redux/store";
import { getCountries } from "./countries";
import { getNonCompanyLinkTypes } from "./links";
import { getLabelTypeFromString, labelTypes } from "./constants";
import { findById, hasMatchingId, idsAreEqual } from "./stringAndArray";
import { getListTypeByCode, getReferenceDisplayNameFromTypeId } from "./lookup";

function getDataFieldFromName(state, fieldName) {
  return state.dataField.dataFields.find((dataField) => dataField.name === fieldName);
}

export function getDataFieldsBySubType(subType) {
  const state = store.getState();

  return state.dataField.dataFields.filter((dataField) => dataField.subType === subType);
}

export function getDisplayNameForFieldObject(field, matterTypeIds, returnShortTranslation, translatedOverrides) {
  if (!field) return "";
  let fieldDisplayName = "";
  if (field.fieldName.includes("REF")) fieldDisplayName = getReferenceDisplayNameFromTypeId(field.fieldName.slice(3));
  else if (field.isListType) fieldDisplayName = getListTypeByCode(field.fieldName)?.displayName;
  else
    fieldDisplayName = getDisplayNameForFieldName(
      field.fieldName,
      matterTypeIds,
      returnShortTranslation,
      translatedOverrides
    );
  return fieldDisplayName;
}

export function getDisplayNameForFieldName(fieldName, matterTypeIds, returnShortTranslation, translatedOverrides) {
  if (fieldName === "contactLinks") return t("Contacts");
  if (fieldName === "countries") return t("Countries");
  const state = store.getState();

  try {
    let outputString = "";
    const dataFieldOverrides = translatedOverrides ?? state.dataField.translatedOverrides;

    const overrideTranslations = dataFieldOverrides.filter((override) =>
      matterTypeIds?.some(
        (matterTypeId) => idsAreEqual(override.matterTypeId, matterTypeId) && override.fieldName === fieldName
      )
    );
    if (overrideTranslations.length > 0) {
      const translatedOverrides = overrideTranslations.map((ot) => ot.displayName);
      outputString = translatedOverrides.join(" / ");
    }

    if (overrideTranslations.length > 0 && matterTypeIds.length !== overrideTranslations.length) {
      outputString += " / ";
    }

    if (!matterTypeIds || matterTypeIds.length === 0 || matterTypeIds.length !== overrideTranslations.length) {
      const dataField = getDataFieldFromName(state, fieldName);
      let translatedString = state.locale.translations[dataField.translationCode];
      if (returnShortTranslation && state.locale.shortTranslations.hasOwnProperty(dataField.translationCode))
        translatedString = state.locale.shortTranslations[dataField.translationCode];
      outputString += translatedString;
    }

    if (state.locale.selectedLocale === "TEST") outputString = `!_${outputString}`;

    return outputString;
  } catch {
    return fieldName;
  }
}

export function getDataTypeForField(fieldName) {
  try {
    const state = store.getState();
    const dataField = getDataFieldFromName(state, fieldName);
    return dataField.lookupDataSourceName ? labelTypes.LOOKUP : getLabelTypeFromString(dataField.dataType);
  } catch {
    return labelTypes.UNKNOWN;
  }
}

export function getLookupValueForField(fieldName, value) {
  if (!value) return "";

  if (Array.isArray(value)) {
    const outputArray = value.map((item) => getSingleFieldLookupValue(fieldName, item));
    return outputArray.join(", ");
  }

  return getSingleFieldLookupValue(fieldName, value);
}

function getSingleFieldLookupValue(fieldName, value) {
  //if (fieldName === "matterLegalTeam-allRoles-MatterContactId") debugger;
  try {
    const state = store.getState();
    const dataField = getDataFieldFromName(state, fieldName);

    const lookupSource = state.lookup.global.find((lookup) => lookup.name === dataField.lookupDataSourceName);
    if (!lookupSource) {
      return value;
    }
    if (dataField.lookupDataSourceName === "Languages") {
      const lookupRow = lookupSource.lookup.find((lookup) => lookup.languageCode === value);
      return state.locale.translations[lookupRow.translationCode];
    }
    if (typeof value === "string") value = parseInt(value); // Sometimes ids come back from the API as strings
    if (dataField.lookupDataSourceName === "Users") {
      const lookupRow = findById(lookupSource.lookup, value);
      return lookupRow.fullName;
    }
    //TODO return fullName
    if (dataField.lookupDataSourceName === "LegalTeam") {
      const lookupRow = findById(lookupSource.lookup, value);
      return `${lookupRow.givenName} ${lookupRow.familyName}`;
    }
    const lookupRow = findById(lookupSource.lookup, value);
    return state.locale.translations[lookupRow.translationCode];
  } catch {
    return value;
  }
}

export function getAllLookupValuesForField(fieldName, matterTypeIds, isProcessingField) {
  try {
    const state = store.getState();
    const dataField = getDataFieldFromName(state, fieldName);
    const lookupSource = state.lookup.global.find((lookup) => lookup.name === dataField.lookupDataSourceName);
    if (!lookupSource) {
      return [];
    }
    let lookupValues = lookupSource.lookup;
    // If property present, only get lookup values that match with the owning matter type

    if (dataField.lookupDataSourceName === "Countries") {
      // Special case for countries
      lookupValues = getCountries(fieldName, matterTypeIds);
    } else if (fieldName === "matterLink_LinkTypeId") {
      // Special case for Linked Matter Link Types - don't display company link types
      lookupValues = getNonCompanyLinkTypes(matterTypeIds);
    } else if (matterTypeIds?.length > 0 && lookupSource.lookup[0].hasOwnProperty("matterTypeIds")) {
      lookupValues = lookupSource.lookup.filter(
        (lookup) =>
          lookup.matterTypeIds.length === 0 || lookup.matterTypeIds.some((mtid) => matterTypeIds.includes(mtid))
      );
    }

    lookupValues = lookupValues.map((lookup) => {
      // TODO: May be removed after DB refactor to make languages conform to the standard model
      if (
        fieldName === "matter_LanguageCode" ||
        fieldName === "matter_SecondLanguageCode" ||
        fieldName === "matterGoods_LanguageCode"
      ) {
        return {
          id: lookup.languageCode,
          displayValue: `${
            state.locale.translations.hasOwnProperty(lookup.translationCode)
              ? state.locale.translations[lookup.translationCode]
              : lookup.translationCode
          } (${lookup.localName})`,
          optionalCodes: null,
        };
      }

      // Lookup source specific mappings
      if (dataField.lookupDataSourceName === "Users") {
        return {
          id: lookup.id,
          displayValue: lookup.fullName,
          optionalCodes: null,
        };
      }

      //Legal Team - matter & actions
      if (fieldName.includes("LegalTeam") && isProcessingField) {
        return {
          id: lookup.id,
          displayValue: `${lookup.givenName} ${lookup.familyName}`,
          optionalCodes: null,
        };
      }

      return {
        id: lookup.id,
        displayValue: state.locale.translations.hasOwnProperty(lookup.translationCode)
          ? state.locale.translations[lookup.translationCode]
          : lookup.translationCode,
        optionalCodes: `${lookup.isoCodeAlpha2 ?? ""} ${lookup.isoCodeAlpha3 ?? ""}`,
        listTypeId: lookup.listTypeId,
      };
    });
    return lookupValues;
  } catch {
    return fieldName;
  }
}

export function getSubTypeForField(fieldName) {
  const state = store.getState();
  const dataField = getDataFieldFromName(state, fieldName);
  return dataField?.subType;
}

export function setOverrideDisplayNames() {
  const state = store.getState();
  const dataFieldOverrideLookups = state.lookup.global.find((lookup) => lookup.name === "DataFieldOverrides").lookup;
  const translatedOverrides = dataFieldOverrideLookups.map((dfol) => {
    const fieldName = dfol.dataFieldName;
    const displayName = state.locale.translations[dfol.translationCode];
    const dataField = getDataFieldFromName(state, fieldName);
    const group = dataField.dataFieldGroupName;
    const dataType = dataField.lookupDataSourceName ? labelTypes.LOOKUP : getLabelTypeFromString(dataField.dataType);
    const qualifyType = dataField.qualifyType;
    const matterTypeId = dfol.matterTypeId;
    return { fieldName, displayName, group, dataType, qualifyType, matterTypeId };
  });
  store.dispatch(setTranslatedOverrides(translatedOverrides));
  return translatedOverrides;
}

export function setDataGroupDisplayNames() {
  const state = store.getState();
  const dataFieldGroups = state.dataField.groups;
  const transaltedDataFieldGroups = dataFieldGroups.map((group) => {
    const dataFieldGroup = { ...group, displayName: state.locale.translations[group.translationCode] };
    return dataFieldGroup;
  });
  store.dispatch(setDataFieldGroups(transaltedDataFieldGroups));
}

// Go through all field names in store and check if supplied fieldName is unique within a particular data source
export function isFieldNameUnique(fieldName) {
  const displayName = getDisplayNameForFieldName(fieldName);
  const state = store.getState();
  const dataFields = state.dataField.dataFields;
  const dataSourceNames = dataFields
    .filter((df) => getDisplayNameForFieldName(df.name) === displayName)
    .map((df) => df.dataSourceName);
  // Dedupe data source names and compare lengths - equal lengths means all data sources are unique for this fieldName
  const uniqueDataSourceNames = new Set(dataSourceNames);
  return dataSourceNames.length === uniqueDataSourceNames.size;
}

export function isAccessRightField(fieldName) {
  const state = store.getState();
  const dataField = getDataFieldFromName(state, fieldName);
  return dataField?.isAccessRightFilter;
}

export function getValidMatterTypesForLookupValue(fieldName, value, matterTypeIds) {
  try {
    const state = store.getState();
    const dataField = getDataFieldFromName(state, fieldName);

    const lookupSource = state.lookup.global.find((lookup) => lookup.name === dataField.lookupDataSourceName);
    if (!lookupSource) {
      return value;
    }
    const lookupRow = findById(lookupSource.lookup, value);

    const lookupMatterTypes = state.lookup.global.find((lookup) => lookup.name === "MatterTypes");
    const matterTypesMatchingFilters = lookupMatterTypes.lookup.filter((mt) => hasMatchingId(matterTypeIds, mt.id));
    const validMatterTypes = [];
    lookupRow.matterTypeIds.forEach((mtid) => {
      const mt = findById(matterTypesMatchingFilters, mtid);
      if (mt) validMatterTypes.push(state.locale.translations[mt.translationCode]);
    });
    return validMatterTypes;
  } catch {
    return value;
  }
}

export function getMatterTypeDisplayNamesFromIds(matterTypeIds) {
  const state = store.getState();
  const lookupMatterTypes = state.lookup.global.find((lookup) => lookup.name === "MatterTypes");
  const matterTypes = lookupMatterTypes.lookup.filter((mt) => hasMatchingId(matterTypeIds, mt.id));
  return matterTypes.map((mt) => state.locale.translations[mt.translationCode]).join(", ");
}
