// API Functions for the batch updating of matter records
import { setBatchUpdateId, setRecordIds, setRowExistingValues, setRowOperationId } from "redux/batchUpdateSlice";
import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";
import { getListTypeByCode } from "utilities/lookup";

// Get the master ID to be passed in all other batch update calls
export const getBatchUpdateId = async (recordIds, isNewRequest) => {
  const state = store.getState();
  const customerId = state.app.customerId;
  const searchLogId = state.searchResults.logId;
  const body = { searchLogId, recordIds };
  const response = await fetchWrapper.post(`customers/${customerId}/batchUpdates`, body);
  store.dispatch(setBatchUpdateId(response.id));
  if (isNewRequest) store.dispatch(setRecordIds(recordIds));
  return response;
};

// Get the field values for a given field name for the supplied batch update ID
export const getExistingFormFieldValues = async (rowItem) => {
  const state = store.getState();
  const fieldName = rowItem.fieldName;
  let response = null;
  if (fieldName.includes("REF")) {
    response = await fetchWrapper.get(
      `customers/${state.app.customerId}/batchUpdates/${state.batchUpdate.id}/DistinctReferenceValues/${fieldName.slice(
        3
      )}`
    );
  } else if (rowItem.isListType) {
    const listTypeDetails = getListTypeByCode(fieldName);
    response = await fetchWrapper.get(
      `customers/${state.app.customerId}/batchUpdates/${state.batchUpdate.id}/DistinctListValues/${listTypeDetails.id}`
    );
  } else {
    response = await fetchWrapper.get(
      `customers/${state.app.customerId}/batchUpdates/${state.batchUpdate.id}/DistinctFieldValues/${fieldName}`
    );
  }
  store.dispatch(setRowExistingValues({ rowItem, existingValues: response, tableName: "formFields" }));
  store.dispatch(setRowOperationId({ rowItem, operationId: 0, tableName: "formFields" }));
  return response;
};

// Get the field values for a given field name for the supplied batch update ID
export const getExistingCompanyLinksByLinkTypeId = async (rowItem) => {
  const state = store.getState();
  const response = await fetchWrapper.get(
    `customers/${state.app.customerId}/batchUpdates/${state.batchUpdate.id}/distinctCompanyLinkValues/${rowItem.id}`
  );
  store.dispatch(setRowExistingValues({ rowItem, existingValues: response, tableName: "companyLinks" }));
  store.dispatch(setRowOperationId({ rowItem, operationId: 0, tableName: "companyLinks" }));
  return response;
};

export const batchUpdateSubmit = async (submissionDataByType) => {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = submissionDataByType;
  const response = await fetchWrapper.post(`customers/${customerId}/BatchUpdates/${state.batchUpdate.id}/run`, body);
  return response;
};
