import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "locale/dictionary";

function ResetPassword({ usernameTextIn, onBackToLogin, onResetPassword }) {
  const [usernameText, setUsernameText] = useState(usernameTextIn);

  const handleUsernameTextChange = (e) => {
    setUsernameText(e.target.value);
  };

  const handleResetPassword = () => {
    onResetPassword(usernameText);
  };

  return (
    <>
      <div className="login__row">
        <div className="textbox">
          <div className="textbox__label">{t("Username")}</div>
          <input value={usernameText} onChange={handleUsernameTextChange} />
        </div>
      </div>
      <button onClick={handleResetPassword}>{t("Reset Password")}</button>
      <p>&nbsp;</p>
      <div className="link" onClick={onBackToLogin}>
        {t("Back to Login")}
      </div>
    </>
  );
}

ResetPassword.propTypes = {
  usernameTextIn: PropTypes.string,
  onBackToLogin: PropTypes.func,
  onResetPassword: PropTypes.func,
};

export default ResetPassword;
