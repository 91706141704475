// Compares two filter arrays and returns true if they are equal, false if not
export function deepCompare(arr1, arr2) {
  // Check if lengths of both arrays are the same
  if (!arr1 || !arr2 || arr1.length === 0 || arr2.length === 0 || arr1.length !== arr2.length) return false;

  // Sort arrays based on fieldName for comparison
  const sortedArr1 = [...arr1].sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1));
  const sortedArr2 = [...arr2].sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1));

  // Compare each filter object
  for (let i = 0; i < sortedArr1.length; i++) {
    // For the value array, first ensure all numbers are in Number format, then sum them up like a checksum -
    // while not 100% exact, it's far easier than trying to compare arrays of numbers as that would also require a sort
    const valueArray1Checksum = sortedArr1[i].valueArray
      ?.map((v) => (typeof v === "number" ? v : parseInt(v)))
      .reduce((a, b) => a + b, 0);
    const valueArray2Checksum = sortedArr2[i].valueArray
      ?.map((v) => (typeof v === "number" ? v : parseInt(v)))
      .reduce((a, b) => a + b, 0);

    if (
      sortedArr1[i].fieldName !== sortedArr2[i].fieldName ||
      sortedArr1[i].operator !== sortedArr2[i].operator ||
      valueArray1Checksum !== valueArray2Checksum ||
      sortedArr1[i].qualified !== sortedArr2[i].qualified
    ) {
      return false;
    }
  }

  return true;
}
