import useComponentVisible from "components/global/useComponentVisible";
import { getLocales, t } from "locale/dictionary";
import { FaUser } from "react-icons/fa";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { changeLocale } from "redux/localeSlice";
import { useNavigate } from "react-router-dom";

// Container for the user menu/popup in the site header's sys tray
export default function UserPopup() {
  const localeState = useSelector((state) => state.locale);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [refPopup, showMainPopup, setShowMainPopup] = useComponentVisible(false);
  const [refLanguagePopup, showLanguagePopup, setShowLanguagePopup] = useComponentVisible(false);
  const [selectedLocaleName, setSelectedLocaleName] = useState("");
  const locales = getLocales();

  useEffect(() => {
    const selectedLocaleNameLocal = locales.find((locale) => locale.code === localeState.selectedLocale).name;
    setSelectedLocaleName(selectedLocaleNameLocal);
  }, [localeState]);

  // useEffect(() => {
  //   console.log("showLanguagePopup state: " + showLanguagePopup);
  // }, [showLanguagePopup]);

  const onLogoutClick = () => {
    document.cookie = "jwt=; path=/; Max-Age=0";
    document.cookie = "u=; path=/; Max-Age=0";
    document.cookie = "p=; path=/; Max-Age=0";
    document.cookie = "n=; path=/; Max-Age=0";
    window.location.replace("/"); // Reload the page at the root location
    setShowMainPopup(false);
  };

  const onSetShowLanguagePopup = (visibility) => {
    // console.log("input visibility: " + visibility);
    // console.log("showLanguagePopup: " + showLanguagePopup);
    setShowLanguagePopup(visibility);
  };

  const handleLocaleChange = (localeCode) => {
    dispatch(changeLocale(localeCode));
    onSetShowLanguagePopup(false);
  };

  const renderLanguagePopup = showLanguagePopup && (
    <div className="language-dropdown__popup" ref={refLanguagePopup}>
      {locales.map((locale) => (
        <p onClick={() => handleLocaleChange(locale.code)} key={locale.code} value={locale.code}>
          {locale.name}
        </p>
      ))}
    </div>
  );

  return (
    <div className="header__user-dropdown" onClick={() => setShowMainPopup(true)} ref={refPopup}>
      <FaUser />
      {showMainPopup && (
        <>
          <div className="user-dropdown__popup">
            <p>{t("Profile Settings")}</p>
            <p onClick={() => onSetShowLanguagePopup(true)}>{`${t("Language")} - ${selectedLocaleName}`}</p>
            <p onClick={onLogoutClick}>{t("Log Out")}</p>
          </div>
          {renderLanguagePopup}
        </>
      )}
    </div>
  );
}
