import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setBearerToken,
  setCustAdmin,
  setCustomerId,
  setHasLoadedInitialData,
  setIsAuthenticated,
  setSysAdmin,
  setUserId,
} from "redux/appSlice";
import { setFullName } from "redux/customerSlice";
import { login, getCookies, hasValidTokenCookie, initialLoader } from "utilities/authentication";
import { idsAreEqual } from "utilities/stringAndArray";
import { t } from "locale/dictionary";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import CustomerSelection from "./CustomerSelection";

const modes = {
  INITIAL: 0,
  AUTHENTICATING: 1,
  LOGIN: 2,
  FORGOTPASSWORD: 3,
  SELECTCUSTOMER: 4,
  PRELOAD: 5,
  COMPLETE: 6,
};

// Functionality for authentication of a user
export default function Authentication() {
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const [mode, setMode] = useState(modes.INITIAL);
  const [usernameText, setUsernameText] = useState("");
  const [authInfo, setAuthInfo] = useState(null);

  const loadData = useRef(false);

  useEffect(() => {
    const doInitialLoad = async () => {
      await initialLoader();
      setMode(modes.COMPLETE);
      dispatch(setHasLoadedInitialData(true));

      if (!customerId) {
        navigate(`/${appState.customerId}/`);
      }
    };
    if (loadData.current && appState.customerId) {
      loadData.current = false;
      doInitialLoad();
    }
  }, [loadData, appState.customerId, customerId, dispatch, navigate]);

  useEffect(() => {
    // Check for a change in the customerId parameter
    //    This useEffect is only called when the customerId parameter changes
    //    The initial call of this useEffect can be ignored as it is handled by the INITIAL mode process
    //    This useEffect is only applicable to the sysadmin users which can switch between customers
    if (appState.isSysAdmin && mode === modes.COMPLETE) {
      const customerIdLocal = customerId ? Number(customerId) : null;

      if (appState.customerId !== customerIdLocal) {
        setMode(customerId ? modes.PRELOAD : modes.COMPLETE);
        dispatch(setCustomerId(customerIdLocal));
        dispatch(setHasLoadedInitialData(false));
        loadData.current = !!customerId;
      }
    }
  }, [customerId, mode, appState.customerId, appState.isAuthenticated, appState.isSysAdmin, authInfo, dispatch]);

  const authenticateByCookies = () => {
    const authInfoLocal = getCookies();

    if (authInfoLocal?.token) {
      // Unexpired token (authenticated user)
      processAuthenticatedResult(authInfoLocal);
    } else if (authInfoLocal === null || authInfoLocal.username.length === 0 || authInfoLocal.password.length === 0) {
      setMode(modes.LOGIN);
    } else {
      // Attempt to login with creditials from cookie
      const authInfo = login(authInfoLocal.username, authInfoLocal.password);
      if (authInfo) {
        processAuthenticatedResult(authInfo);
      } else {
        setUsernameText(authInfoLocal.username);
        setMode(modes.LOGIN);
      }
    }
  };

  const processAuthenticatedResult = (authInfo) => {
    setAuthInfo(authInfo);
    if (authInfo.sysAdmin) {
      setKeyAppStateVariables(customerId ? Number(customerId) : null, authInfo);
      setMode(customerId ? modes.PRELOAD : modes.COMPLETE);
      loadData.current = !!customerId;
    } else if (authInfo?.customers?.length === 1 || customerId) {
      setKeyAppStateVariables(customerId ? Number(customerId) : authInfo.customers[0].customerId, authInfo);
      setMode(modes.PRELOAD);
      loadData.current = true;
    } else if (authInfo?.customers?.length > 1) {
      setMode(modes.SELECTCUSTOMER);
    } else {
      setMode(modes.LOGIN);
    }
  };

  const setKeyAppStateVariables = (customerIdIn, authInfo) => {
    dispatch(setBearerToken(authInfo.token));
    dispatch(setFullName(authInfo.fullName));
    dispatch(setSysAdmin(authInfo.sysAdmin));
    dispatch(setUserId(authInfo.id));
    dispatch(setIsAuthenticated(true));

    if (customerIdIn) {
      dispatch(setCustomerId(customerIdIn));

      if (authInfo?.custAdmins.length > 0) {
        if (authInfo.custAdmins.find((custAdminObj) => idsAreEqual(custAdminObj.customerId, customerIdIn)))
          dispatch(setCustAdmin(true));
      }
    }
  };

  const handleResetPassword = (usernameText) => {
    navigate(`/new-user?email=${usernameText}`, { replace: false });
  };

  const handleAuthenticated = (authInfo) => {
    processAuthenticatedResult(authInfo);
  };

  const handleForgotPassword = (userName) => {
    setMode(modes.FORGOTPASSWORD);
    setUsernameText(userName);
  };

  const handleSelectCustomer = (customerIdIn) => {
    setKeyAppStateVariables(customerIdIn, authInfo);
    setMode(modes.PRELOAD);
    loadData.current = true;
  };

  if (mode === modes.INITIAL) {
    // On initial load check cookie for authentication
    authenticateByCookies();
  } else if (mode === modes.COMPLETE) {
    // Check if the cookie has expired
    if (!hasValidTokenCookie()) {
      authenticateByCookies();
    }
  }

  const renderPopup = () => {
    let renderBody = null;

    switch (mode) {
      case modes.LOGIN:
        renderBody = <Login onAuthenticated={handleAuthenticated} onForgotPasswordClick={handleForgotPassword} />;
        break;
      case modes.FORGOTPASSWORD:
        renderBody = (
          <ResetPassword
            usernameTextIn={usernameText}
            onResetPassword={handleResetPassword}
            onBackToLogin={() => setMode(modes.LOGIN)}
          />
        );
        break;
      case modes.SELECTCUSTOMER:
        renderBody = <CustomerSelection customers={authInfo?.customers} onCustomerSelected={handleSelectCustomer} />;
        break;
      case modes.PRELOAD:
        renderBody = <div className="login__row">{t("Loading")}...</div>;
        break;
      default:
        break;
    }

    return renderBody === null ? null : (
      <div className="login">
        <div className="login__popup">
          <div className="login__header">{t("Login")}</div>
          <div className="login__body">{renderBody}</div>
        </div>
      </div>
    );
  };

  return renderPopup();
}
