import { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "components/global/Checkbox";
import { t } from "locale/dictionary";
import { login } from "utilities/authentication";

function Login({ onAuthenticated, onForgotPasswordClick }) {
  const [usernameText, setUsernameText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUsernameTextChange = (e) => {
    setUsernameText(e.target.value);
  };

  const handlePasswordTextChange = (e) => {
    setPasswordText(e.target.value);
  };

  const handleAuthenticateClick = async () => {
    const authInfoLocal = await login(usernameText, passwordText, isRememberMe);
    if (authInfoLocal) {
      if ((!authInfoLocal.customers || authInfoLocal.customers.length === 0) && authInfoLocal.sysAdmin !== true) {
        setErrorMessage(t("You have no access to any customers"));
        return;
      }
      setErrorMessage(t(null));
      onAuthenticated(authInfoLocal);
    } else {
      setErrorMessage(t("Username or password is not recognised"));
    }
  };

  return (
    <>
      <div className="login__row">
        <div className="textbox">
          <div className="textbox__label">{t("Username")}</div>
          <input value={usernameText} onChange={handleUsernameTextChange} />
        </div>
      </div>
      <div className="login__row">
        <div className="textbox">
          <div className="textbox__label">{t("Password")}</div>
          <input type="password" value={passwordText} onChange={handlePasswordTextChange} />
        </div>
      </div>
      <button onClick={handleAuthenticateClick}>{t("Submit")}</button>
      <div className="login__row">
        <Checkbox
          label={t("Remember me on this computer")}
          isChecked={isRememberMe}
          onCheckChange={() => setIsRememberMe(!isRememberMe)}
        />
      </div>
      <div className="link" onClick={() => onForgotPasswordClick(usernameText)}>
        {t("Forgot password?")}
      </div>
      {errorMessage !== null && (
        <div className="login__row">
          <div className="validation-error">{errorMessage}</div>
        </div>
      )}
    </>
  );
}

Login.propTypes = {
  onAuthenticated: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
};

export default Login;
